.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-modal-body {
    padding: 0px;
    padding-top: 12px;
}

.ant-modal-footer {
    padding: 0px;
}

.custom-modal .ant-modal-footer {
    border-top: 0px;
}

.ant-modal-close-x {
    width: 22px;
    height: 17px;
}

.ql-container.ql-snow {
    height: auto;
}
.ql-editor {
    height: 170px;
    overflow-y: scroll;
}

.detail-header-flexbox {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-bottom: 24px;
}

.detail-content-flexbox {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

svg {height: auto; width: auto}
#map { height: 180px; }
